
 import  { listenKeys } from "nanostores"

import "./checkout-product-card-v2"
import Cart from "./checkout-helpers-cart-v1"
import ExistingOrders from "./checkout-helpers-existing-orders-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutProductSelectV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    const productCardComponents = this.getComponents("CheckoutProductCard/V2");
    const productSelectElement = this.element
    CheckoutProductSelectV2.registerOneTimeCardElementListeners(productCardComponents, productSelectElement, (pcc) => { this.registerProductEventListeners(pcc) })
    productCardComponents.forEach((pcc) => {
      CheckoutProductSelectV2.registerEventListeners(pcc, (pcc) => { this.registerProductEventListeners(pcc) })
    })
  }

  registerVariantEventListeners(pcc) {
    const element = pcc.element
    const variantSelects = element.querySelectorAll('.elVariantSelector')

    variantSelects.forEach((select, index) => {
      select.addEventListener('click', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()
      })

      select.addEventListener('change', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()

        const newValues = [...variantSelects].map((e) => e.value)
        
        const selectedVariantId = this.getSelectedVariant(pcc.product.sorted_property_values, 
          pcc.product.property_values_variant_mapping, 
          pcc.variantValues, pcc.valuesPositions, index, newValues
        )

        const oldCartItemId = CheckoutProductSelectV2.getId(pcc)
        const newVariant = pcc.product.variants.find((v) => v.id == String(selectedVariantId))
        const newPrice = newVariant.prices[0]
        let newCartItemId, selectedUpdatableCartItemId
        if (pcc.selectedUpdatableCartItemId) {
          const cartIdDetails = Cart.getCartIdDetails(pcc.selectedUpdatableCartItemId)
          newCartItemId = Cart.buildCartUpdatableItemId(
            cartIdDetails.type,
            cartIdDetails.orderId, 
            cartIdDetails.lineItemId,
            cartIdDetails.productId,
            newVariant.id,
            newPrice.id
          )
          selectedUpdatableCartItemId = newCartItemId
        } else {
          newCartItemId = Cart.buildCartItemId(pcc.product.id, newVariant.id, newPrice.id)
        }
        
        Cart.dispatchEvents(Cart.replace(oldCartItemId, newCartItemId))
        CheckoutProductSelectV2.updateCardByProductIdState(pcc.product.id, {variantId: newVariant.id, priceId: newPrice.id, selectedUpdatableCartItemId})
      })
    })
  }

  registerUpdatableOrders(pcc) {
    const element = pcc.element
    const previousOrders = element.querySelectorAll('.elProductCardOrder')
    const newOrder = element.querySelector('.elProductCardNewOrder')

    previousOrders?.forEach((order) => {
      const showOrderDetailsLink = order.querySelector('.elProductCardShowOrderDetailsLink')
      const upgradeAndDowngradeSelector = order.querySelector('[name="upgradeDowngrade"]')
      const orderId = order.getAttribute('data-order-id')

      order.addEventListener('click', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()
        const productId = pcc.product.id
        let oldCartItemId, newCartItemId, updateCardNewState
        if (Checkout.store.orderDetailsByProductId.get()[productId].orders[orderId].reactivation) {
          const { variantId, priceId, lineItemId } = Checkout.store.orderDetailsByProductId.get()[productId].orders[orderId]
          const selectedUpdatableCartItemId = Cart.buildCartUpdatableItemId(
            Cart.ITEM_TYPES.REACTIVATE,
            orderId, 
            lineItemId,
            productId,
            variantId,
            priceId
          )
          const renderType = Checkout.CheckoutStates.REACTIVATE

          if (pcc.selectedUpdatableCartItemId) {
            oldCartItemId = pcc.selectedUpdatableCartItemId
          } else {
            oldCartItemId = CheckoutProductSelectV2.getId(pcc)
          }
          updateCardNewState = {
            selectedOrderId: orderId,
            selectedUpdatableCartItemId,
            variantId,
            priceId,
            renderType,
          }
          newCartItemId = selectedUpdatableCartItemId
        } else {
          const selectedUpdatableCartItemId = Checkout.store.orderDetailsByProductId.get()[productId].orders[orderId].updatableCartItemIds[0]
          const { variantId, priceId }  = Cart.getCartIdDetails(selectedUpdatableCartItemId)
          const renderType = Checkout.CheckoutStates.UPGRADE_DOWNGRADE

          if (pcc.selectedUpdatableCartItemId) {
            oldCartItemId = pcc.selectedUpdatableCartItemId
          } else {
            oldCartItemId = CheckoutProductSelectV2.getId(pcc)
          }
          updateCardNewState = {
            selectedOrderId: orderId,
            selectedUpdatableCartItemId,
            variantId,
            priceId,
            renderType,
          }
          newCartItemId = selectedUpdatableCartItemId
        }

        Cart.dispatchEvents(Cart.replace(oldCartItemId, newCartItemId))
        CheckoutProductSelectV2.updateCardByProductIdState(productId, updateCardNewState)
      })

      showOrderDetailsLink?.addEventListener('click', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()
        Checkout.store.selectedOrderDetailId.set(orderId)
      })

      upgradeAndDowngradeSelector?.addEventListener('click', (evt) => {
        evt.preventDefault()
        evt.stopImmediatePropagation()
        evt.stopPropagation()
      })

      upgradeAndDowngradeSelector?.addEventListener('change', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()
        const oldCartItemId = pcc.selectedUpdatableCartItemId
        const newCartItemId = evt.target.value
        const { variantId } = Cart.getCartIdDetails(newCartItemId)
        if (oldCartItemId != newCartItemId) {
          CheckoutProductSelectV2.updateCardByProductIdState(pcc.product.id, {variantId: variantId, selectedUpdatableCartItemId: newCartItemId})
          Cart.dispatchEvents(Cart.replace(oldCartItemId, newCartItemId))
        }
      })
    })

    newOrder?.addEventListener('click', () => {
      const variant = pcc.product.variants[0]
      const variantId = variant.id
      const priceId = variant.prices[0].id
      const productId = pcc.product.id
      let oldCartItemId
      if (pcc.selectedUpdatableCartItemId) {
        oldCartItemId = pcc.selectedUpdatableCartItemId
      } else {
        oldCartItemId = CheckoutProductSelectV2.getId(pcc)
      }

      const updateCardNewState = {
        selectedOrderId: 'new',
        variantId,
        priceId,
        selectedUpdatableCartItemId: undefined,
      }
      const newCartItemId = Cart.buildCartItemId(productId, variantId, priceId)
      Cart.dispatchEvents(Cart.replace(oldCartItemId, newCartItemId))
      CheckoutProductSelectV2.updateCardByProductIdState(productId, updateCardNewState)
    })
  }

  registerProductEventListeners(pcc) {
    this.registerUpdatableOrders(pcc)
    this.registerVariantEventListeners(pcc)
  }

  static getId(pcc) {
    if (pcc.selectedUpdatableCartItemId) {
      return pcc.selectedUpdatableCartItemId
    } else {
      return Cart.buildCartItemId(pcc.product.id, pcc.variant.id, pcc.selected_price.id)
    }
  }

  static registerPriceEventListeners(pcc) {
    const element = pcc.element
    const variantPriceSelector = element.querySelector('[name="variant_price"]')
    variantPriceSelector?.addEventListener('click', (evt) => {
      evt.preventDefault()
      evt.stopImmediatePropagation()
      evt.stopPropagation()
    })

    variantPriceSelector?.addEventListener('change', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
      const oldCartItemId = this.getId(pcc)
      const newPriceId = evt.target.value
      let newCartItemId, selectedUpdatableCartItemId
      if (pcc.selectedUpdatableCartItemId) {
        const cartIdDetails = Cart.getCartIdDetails(pcc.selectedUpdatableCartItemId)
        newCartItemId = Cart.buildCartUpdatableItemId(
          cartIdDetails.type,
          cartIdDetails.orderId, 
          cartIdDetails.lineItemId,
          cartIdDetails.productId,
          cartIdDetails.variantId,
          newPriceId
        )
        selectedUpdatableCartItemId = newCartItemId
      } else {
        newCartItemId = Cart.buildCartItemId(pcc.product.id, pcc.variant.id, newPriceId)
      }

      Cart.dispatchEvents(Cart.replace(oldCartItemId, newCartItemId))
      this.updateCardByProductIdState(pcc.product.id, {priceId: evt.target.value, selectedUpdatableCartItemId})
    })
  }

  static registerQuantityEventListeners(pcc) {
    const element = pcc.element
    const [minusButton, plusButton] = element.querySelectorAll('.elProductInputControls button')
    const input = element.querySelector('.elProductInputControls input')
    const pccCartItemId = this.getId(pcc)
    minusButton.addEventListener('click', (evt) => {
      evt.preventDefault()
      if (pcc.quantity === 0) return
      const newQuantity = pcc.quantity - 1
      this.updateCardByProductIdState(pcc.product.id, {quantity: newQuantity})
      if (newQuantity === 0) {
        Cart.dispatchEvents(Cart.remove(pccCartItemId))
      } else {
        Cart.dispatchEvents(Cart.add(pccCartItemId, newQuantity))
      }
    })
    plusButton.addEventListener('click', (evt) => {
      evt.preventDefault()
      const newQuantity = pcc.quantity + 1
      this.updateCardByProductIdState(pcc.product.id, {quantity: newQuantity})
      Cart.dispatchEvents(Cart.add(pccCartItemId, newQuantity))
    })
    input.addEventListener('blur', (evt) => {
      const newQuantity = parseInt(evt.target.value)
      if (newQuantity == 0 || isNaN(newQuantity)) {
        this.updateCardByProductIdState(pcc.product.id, {quantity: 0})
        Cart.dispatchEvents(Cart.remove(pccCartItemId))
      } else {
        this.updateCardByProductIdState(pcc.product.id, {quantity: newQuantity})
        Cart.dispatchEvents(Cart.add(pccCartItemId, newQuantity))
      }
    })
  }

  getSelectedVariant(propertyValues, valuesVariants, variantValues, 
    valuesPositions, selectedPropertyIndex, newSelectedValue) {
    // const propertyValues = [
    //   { property_id: 10, value_ids: [10, 11, 12] },
    //   { property_id: 11, value_ids: [15, 16, 17] },
    //   //  etc
    // ]
    // const variantValues = {
    //   7: [10, 15],
    //   // 8: [11, 15],
    //   9: [12, 15],
    //   10: [10, 16],
    //   11: [11, 16],
    //   12: [12, 16],
    //   13: [10, 17],
    //   14: [11, 17],
    //   15: [12, 17],
    // }
    let selectedValuePath = newSelectedValue.join(',')

    if (!valuesVariants[selectedValuePath]) {
      const distances = []
      const rightPart = this.range(selectedPropertyIndex + 1, propertyValues.length - 1, 1)
      const leftPart = this.range(0, selectedPropertyIndex - 1, 1)
      const orderedIndexes = rightPart.concat(leftPart)
      Object.values(variantValues).forEach((values) => {
        if (values[selectedPropertyIndex] == newSelectedValue[selectedPropertyIndex]) {
          let distance = 0
          let changedProperties = 0
          orderedIndexes.forEach((position, count) => {
            if (values[position] != newSelectedValue[position]) {
              changedProperties += 1
              distance = 10000 * count + valuesPositions[values[position]] - valuesPositions[newSelectedValue[position]]
            }
          })
          distances.push({
            distance: distance,
            changedProperties: changedProperties,
            variantPath: values.join(','),
          })
        }
      })
      distances.sort((d1, d2) => {
        const diffChangedProperties = d1.changedProperties - d2.changedProperties
        if (diffChangedProperties != 0) return diffChangedProperties
        return d1.distance - d2.distance
      })
      selectedValuePath = distances[0].variantPath
    }
    return valuesVariants[selectedValuePath]
  }

  range (start, stop, step) {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
  }

  static renderAndMount(pcc, onMountCallback) {
    pcc.render()
    this.registerEventListeners(pcc, onMountCallback)
  }

  static updateCardByProductIdState(productId, data) {
    const pccState = Checkout.store.productCardByProductId.get()[productId]
    Checkout.store.productCardByProductId.setKey(productId, {
      ...pccState,
      ...data,
    })
  }

  static renderPcc(currentPccState, newPccState, anyUpdatableOrders, onMountCallback) {
      let newSelectType
      if (anyUpdatableOrders) {
        newSelectType = 'single'
      } else if (!currentPccState.product.bump) {
        newSelectType = Checkout.productSelectType
      } else {
        newSelectType = currentPccState.selectType
      }

      if (
        currentPccState.renderType == newPccState.renderType &&
        currentPccState.variant.id == newPccState.variantId &&
        currentPccState.selected_price.id == newPccState.priceId &&
        currentPccState.quantity == newPccState.quantity &&
        currentPccState.selectedOrderId == newPccState.selectedOrderId &&
        currentPccState.selectedUpdatableCartItemId == newPccState.selectedUpdatableCartItemId &&
        currentPccState.selectType == newSelectType
      ) return

      currentPccState.renderType = newPccState.renderType
      currentPccState.selectType = newSelectType
      currentPccState.variant = Checkout.variantsById[newPccState.variantId]
      currentPccState.selected_price = Checkout.pricesById[newPccState.priceId]
      currentPccState.quantity = newPccState.quantity

      currentPccState.selectedOrderId = newPccState.selectedOrderId
      currentPccState.selectedUpdatableCartItemId = newPccState.selectedUpdatableCartItemId


      currentPccState.isChecked = newPccState.quantity > 0
      if (currentPccState.isChecked) {
        currentPccState.element.classList.add('elProductSelected')
      } else {
        currentPccState.element.classList.remove('elProductSelected')
      }
      this.renderAndMount(currentPccState, onMountCallback)
  }

  static registerOneTimeCardElementListeners(productCardComponents, productSelectElement, onMountCallback) {
    productCardComponents.forEach((pcc) => {
      listenKeys(Checkout.store.productCardByProductId, [pcc.product.id], (productCardByProductId) => {
        const newPccState = Checkout.store.productCardByProductId.get()[pcc.product.id]
        const anyUpdatableOrders = Checkout.computed.anyUpdatableOrders.get()
        this.renderPcc(pcc, newPccState, anyUpdatableOrders, onMountCallback)
      })
    })

    productCardComponents.forEach((pcc) => { 
      const element = pcc.element
      element.addEventListener('click', (evt) => {
        if (pcc.selectType == 'quantity') return
        const button = evt.target.closest('a')
        // NOTE: return if its, for example, a link from card description,
        // but not a bump add/remove button
        if(button && !button.closest('.elProductCardModernButton')) return

        const events = []
        const pccCartItemId = this.getId(pcc)
        if (pcc.selectType == 'single') {
          for (const otherPcc of productCardComponents) {
            if (otherPcc == pcc || !otherPcc.isChecked) continue
            const otherpccCartItemId = this.getId(otherPcc)
            events.push(Cart.remove(otherpccCartItemId))
            this.updateCardByProductIdState(otherPcc.product.id, {quantity: 0})
          }
          if (pcc.id.includes('Bump')) {
            if (pcc.isChecked) {
              events.push(Cart.remove(pccCartItemId))
              this.updateCardByProductIdState(pcc.product.id, {quantity: 0})
            } else {
              events.push(Cart.add(pccCartItemId))
              this.updateCardByProductIdState(pcc.product.id, {quantity: 1})
            }
          } else {
            events.push(Cart.add(pccCartItemId))
            this.updateCardByProductIdState(pcc.product.id, {quantity: 1})
          }
        } else if(pcc.selectType == 'multiple') {
          if (pcc.isChecked) {
            this.updateCardByProductIdState(pcc.product.id, {quantity: 0})
            events.push(Cart.remove(pccCartItemId))
          } else {
            this.updateCardByProductIdState(pcc.product.id, {quantity: 1})
            events.push(Cart.add(pccCartItemId))
          }
        }
        Cart.dispatchEvents(events)
      })
    })
  }

  static registerEventListeners(pcc, onMountCallback) {
    this.registerPriceEventListeners(pcc)
    onMountCallback(pcc)
    if (pcc.selectType == 'quantity') {
      this.registerQuantityEventListeners(pcc)
    }
  }



}

window["CheckoutProductSelectV2"] = CheckoutProductSelectV2

